<template>
    <div>
        <div class="card">
            <div class="card-header card-header-flex pb-2">
                <div class="w-100">
                    <div class="row">
                        <div class="col-6">
                            <h5 class="mt-3 ml-0 mr-3 mb-2 text-uppercase">
                                <span>{{ $t('title.userProfile') }}</span>
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="profile-operation">
                    <b-container fluid>
                        <form @submit.prevent="handleSubmit" autocomplete="off">
                            <b-row class="p-0">
                                <b-col cols="12" md="12" lg="12" sm="12" class="p-0">
                                    <b-row>
                                        <b-col md="3" lg="3" sm="12">
                                            <b-form-group
                                                :label="$t('input.firstName')+' *'"
                                                label-for="first_name"
                                                :invalid-feedback="formErrors.first('first_name')"
                                            >
                                                <b-form-input
                                                    id="first_name"
                                                    v-model="formFields.first_name"
                                                    type="text"
                                                    :state="((formErrors.has('first_name') ? false : null))"
                                                    ref="first_name"
                                                    @focus="$event.target.select()"
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                        <b-col md="3" lg="3" sm="12">
                                            <b-form-group
                                                :label="$t('input.lastName')"
                                                label-for="last_name"
                                                :invalid-feedback="formErrors.first('last_name')"
                                            >
                                                <b-form-input
                                                    id="last_name"
                                                    v-model="formFields.last_name"
                                                    type="text"
                                                    :state="((formErrors.has('last_name') ? false : null))"
                                                    ref="last_name"
                                                    @focus="$event.target.select()"
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                        <b-col md="3" lg="3" sm="12">
                                            <b-form-group
                                                :label="$t('input.email')+' *'"
                                                label-for="email"
                                                :invalid-feedback="formErrors.first('email')">
                                                <b-form-input
                                                    id="email"
                                                    v-model="formFields.email"
                                                    type="text"
                                                    :state="((formErrors.has('email') ? false : null))"
                                                    @focus="$event.target.select()"
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                        <b-col md="3" lg="3" sm="12">
                                            <b-form-group
                                                :label="$t('input.phone')+' *'"
                                                label-for="phone"
                                                :invalid-feedback="formErrors.first('phone')">
                                                <b-form-input
                                                    id="phone"
                                                    v-model="formFields.phone"
                                                    type="text"
                                                    :state="((formErrors.has('phone') ? false : null))"
                                                    @focus="$event.target.select()"></b-form-input>
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                    </b-row><!--/b-row-->
                                    <b-row>
                                        <b-col md="3" lg="3" sm="12">
                                            <b-form-group
                                                :label="$t('input.password')+' *'"
                                                label-for="password"
                                                :invalid-feedback="formErrors.first('password')">
                                                <b-form-input
                                                    id="password"
                                                    v-model="formFields.password"
                                                    type="password"
                                                    :state="((formErrors.has('password') ? false : null))"
                                                    @focus="$event.target.select()"></b-form-input>
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                        <b-col md="3" lg="3" sm="12">
                                            <b-form-group
                                                :label="$t('input.confirmPassword')+' *'"
                                                label-for="password_confirmation">
                                                <b-form-input
                                                    type="password"
                                                    id="password_confirmation"
                                                    v-model="formFields.password_confirmation"
                                                    @focus="$event.target.select()"></b-form-input>
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <b-form-group class="mt-2"
                                            >
                                                <upload v-model="formFields.profile_pic"
                                                        :disabled="formFields.profile_pic"
                                                        :title="$t('button.title.uploadProfile')"
                                                        css-class="mr-2" upload-type="image" thum="profile"></upload>
                                                <b-button :title="$t('button.title.removeProfile')"
                                                          variant="outline-primary" class="mr-2"
                                                          @click="() => {formFields.profile = null; formFields.profile = null}"
                                                          :disabled="!formFields.profile_pic"
                                                          v-if="formFields.profile_pic">
                                                    <i class="fa fa-close"></i>
                                                </b-button>
                                                <a-avatar size="sm" class="mt-3" shape="square" :size="64" icon="user"
                                                          v-if="formFields.profile_pic && formFields.profile_pic.download_url"
                                                          :src="formFields.profile_pic.download_url"/>
                                            </b-form-group>
                                        </b-col>
                                    </b-row><!--/b-row-->
                                    <b-row v-if="$global.hasRole('client')">
                                        <b-col md="9" lg="9" sm="12">
                                            <b-input-group :prepend="$t('input.apiSecret')" class="mt-3">
                                                <b-form-input
                                                    :type="(isPassword ? 'password' : 'text')"
                                                    id="api_secret"
                                                    readonly
                                                    v-model="formFields.api_secret">
                                                </b-form-input>
                                                <b-input-group-append>
                                                    <b-button
                                                        variant="info"
                                                        @click="handleVisibilityClick()">
                                                        <i class="fa fa-eye" v-if="isPassword"></i>
                                                        <i class="fa fa-eye-slash" v-if="!isPassword"></i>
                                                    </b-button>
                                                    <b-button
                                                        variant="warning"
                                                        @click="generateApiSecret()">
                                                        <i class="fa fa-refresh"></i>
                                                    </b-button>
                                                </b-input-group-append>
                                            </b-input-group>
                                        </b-col><!--/b-col-->
                                    </b-row><!--/b-row-->
                                    <hr/>
                                    <b-row class="operation-footer">
                                        <b-col sm="12">
                                            <b-button
                                                size="sm"
                                                type="submit"
                                                variant="primary"
                                                :disabled="global.pendingRequests > 0"
                                                v-b-tooltip.hover :title="$t('button.title.save')"
                                            >
                                                <clip-loader style="display: inline" :loading="true" color="#fff"
                                                             size="12px"
                                                             v-if="global.pendingRequests > 0"></clip-loader>
                                                <i class="fa fa-save mr-1"></i>
                                                {{ $t('button.save') }}
                                            </b-button>
                                            <div>
                                                <small>
                                                    {{$t('msc.actionWillLogOutYou')}}
                                                </small>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </form>
                    </b-container>
                </div>
            </div>
        </div>

        <div class="card"  v-if="$global.hasRole('suppliercompound')">
            <div class="card-header card-header-flex pb-2">
                <div class="w-100">
                    <div class="row">
                        <div class="col-6">
                            <h5 class="mt-3 ml-0 mr-3 mb-2 text-uppercase">
                                <span>WEBHOOKS</span> <br>
                                <small>Every Request should be POST</small> <br>
                                <small><a href="/collection.json">Download</a> Postman Collection</small>
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="webhooks-operation">
                    <b-container fluid>
                        <form @submit.prevent="handleCompoundWebhookSubmit" autocomplete="off">
                            <b-row class="p-0">
                                <b-col cols="12" md="12" lg="12" sm="12" class="p-0">
                                    <b-row>
                                        <b-col sm="12 mt-4">
                                            <b-row>
                                                <b-col sm="6">
                                                    <b-form-group
                                                        :label="$t('input.whenCarrierStartJourney')+' *'"
                                                        label-for="carrier_starts_journey"
                                                        :invalid-feedback="formErrors.first('carrier_starts_journey')">
                                                        <b-form-input
                                                            id="carrier_starts_journey"
                                                            v-model="compoundWebhookFields.carrier_starts_journey"
                                                            type="text"
                                                            :state="((formErrors.has('carrier_starts_journey') ? false : null))"
                                                        ></b-form-input>
                                                    </b-form-group>
                                                    <small>
                                                        Call when Carrier or driver starting the journey
                                                    </small>
                                                </b-col><!--/b-col-->
                                                <b-col sm="6">
                                                    <table class="table table-sm table-striped">
                                                        <tbody>
                                                            <tr>
                                                                <th colspan="10">
                                                                    <div class="clearfix">
                                                                        <div class="pull-left">
                                                                            Params that you will receive
                                                                        </div>
                                                                        <div class="pull-right">
                                                                            <b-button
                                                                                size="sm"
                                                                                type="button"
                                                                                variant="dark"
                                                                                :disabled="global.pendingRequests > 0"
                                                                                @click="handleCompoundTestCall('carrier_starts_journey')"
                                                                                v-b-tooltip.hover
                                                                            >
                                                                                <clip-loader style="display: inline" :loading="true" color="#fff"
                                                                                             size="12px"
                                                                                             v-if="global.pendingRequests > 0"></clip-loader>
                                                                                Test Call
                                                                            </b-button>
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <th width="150">id</th>
                                                                <td>Inbound record id</td>
                                                            </tr>
                                                            <tr>
                                                                <th width="150">driver</th>
                                                                <td>if action did by driver</td>
                                                            </tr>
                                                            <tr>
                                                                <th width="150">event_type</th>
                                                                <td>carrier_starts_journey</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </b-col><!--/b-col-->
                                                <b-col sm="12"><hr></b-col>
                                            </b-row>
                                        </b-col><!--/b-col-->

                                        <b-col sm="12 mt-4">
                                            <b-row>
                                                <b-col sm="6">
                                                    <b-form-group
                                                        :label="$t('input.whenCarrierCreateInboundUnloadRequest')+' *'"
                                                        label-for="carrier_create_inbound_unload"
                                                        :invalid-feedback="formErrors.first('carrier_create_inbound_unload')">
                                                        <b-form-input
                                                            id="carrier_create_inbound_unload"
                                                            v-model="compoundWebhookFields.carrier_create_inbound_unload"
                                                            type="text"
                                                            :state="((formErrors.has('carrier_create_inbound_unload') ? false : null))"
                                                        ></b-form-input>
                                                    </b-form-group>
                                                    <small>
                                                        Call when Carrier or Driver is on Gate of Compound and Request for Unload <br>
                                                        Only call if the compound unload request is pending
                                                    </small>
                                                </b-col><!--/b-col-->
                                                <b-col sm="6">
                                                    <table class="table table-sm table-striped">
                                                        <tbody>
                                                            <tr>
                                                                <th colspan="10">
                                                                    <div class="clearfix">
                                                                        <div class="pull-left">
                                                                            Params that you will receive
                                                                        </div>
                                                                        <div class="pull-right">
                                                                            <b-button
                                                                                size="sm"
                                                                                type="button"
                                                                                variant="dark"
                                                                                :disabled="global.pendingRequests > 0"
                                                                                @click="handleCompoundTestCall('carrier_create_inbound_unload')"
                                                                                v-b-tooltip.hover
                                                                            >
                                                                                <clip-loader style="display: inline" :loading="true" color="#fff"
                                                                                             size="12px"
                                                                                             v-if="global.pendingRequests > 0"></clip-loader>
                                                                                Test Call
                                                                            </b-button>
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <th width="150">id</th>
                                                                <td>Inbound record id</td>
                                                            </tr>
                                                            <tr>
                                                                <th width="150">event_type</th>
                                                                <td>carrier_create_inbound_unload</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </b-col><!--/b-col-->
                                                <b-col sm="12"><hr></b-col>
                                            </b-row>
                                        </b-col><!--/b-col-->

                                        <b-col sm="12 mt-4">
                                            <b-row>
                                                <b-col sm="6">
                                                    <b-form-group
                                                        :label="$t('input.whenClientAddsUpdatesAdditionalService')+' *'"
                                                        label-for="client_add_or_update_additional_service_request"
                                                        :invalid-feedback="formErrors.first('client_add_or_update_additional_service_request')">
                                                        <b-form-input
                                                            id="client_add_or_update_additional_service_request"
                                                            v-model="compoundWebhookFields.client_add_or_update_additional_service_request"
                                                            type="text"
                                                            :state="((formErrors.has('client_add_or_update_additional_service_request') ? false : null))"
                                                        ></b-form-input>
                                                    </b-form-group>
                                                    <small>
                                                        Call when Client Add or Update the Additional Service Request
                                                    </small>
                                                </b-col><!--/b-col-->
                                                <b-col sm="6">
                                                    <table class="table table-sm table-striped">
                                                        <tbody>
                                                            <tr>
                                                                <th colspan="10">
                                                                    <div class="clearfix">
                                                                        <div class="pull-left">
                                                                            Params that you will receive
                                                                        </div>
                                                                        <div class="pull-right">
                                                                            <b-button
                                                                                size="sm"
                                                                                type="button"
                                                                                variant="dark"
                                                                                :disabled="global.pendingRequests > 0"
                                                                                @click="handleCompoundTestCall('client_add_or_update_additional_service_request')"
                                                                                v-b-tooltip.hover
                                                                            >
                                                                                <clip-loader style="display: inline" :loading="true" color="#fff"
                                                                                             size="12px"
                                                                                             v-if="global.pendingRequests > 0"></clip-loader>
                                                                                Test Call
                                                                            </b-button>
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <th width="150">id</th>
                                                                <td>Order record id</td>
                                                            </tr>
                                                            <tr>
                                                                <th width="150">event_type</th>
                                                                <td>client_add_or_update_additional_service_request</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </b-col><!--/b-col-->
                                                <b-col sm="12"><hr></b-col>
                                            </b-row>
                                        </b-col><!--/b-col-->

                                        <b-col sm="12 mt-4">
                                            <b-row>
                                                <b-col sm="6">
                                                    <b-form-group
                                                        :label="$t('input.whenCarrierStartJourneyOutbound')+' *'"
                                                        label-for="carrier_starts_journey_outbound"
                                                        :invalid-feedback="formErrors.first('carrier_starts_journey_outbound')">
                                                        <b-form-input
                                                            id="carrier_starts_journey_outbound"
                                                            v-model="compoundWebhookFields.carrier_starts_journey_outbound"
                                                            type="text"
                                                            :state="((formErrors.has('carrier_starts_journey_outbound') ? false : null))"
                                                        ></b-form-input>
                                                        <small>
                                                            Call when Carrier or driver starting the journey for outbound load
                                                        </small>
                                                    </b-form-group>
                                                </b-col><!--/b-col-->
                                                <b-col sm="6">
                                                    <table class="table table-sm table-striped">
                                                        <tbody>
                                                            <tr>
                                                                <th colspan="10">
                                                                    <div class="clearfix">
                                                                        <div class="pull-left">
                                                                            Params that you will receive
                                                                        </div>
                                                                        <div class="pull-right">
                                                                            <b-button
                                                                                size="sm"
                                                                                type="button"
                                                                                variant="dark"
                                                                                :disabled="global.pendingRequests > 0"
                                                                                @click="handleCompoundTestCall('carrier_starts_journey_outbound')"
                                                                                v-b-tooltip.hover
                                                                            >
                                                                                <clip-loader style="display: inline" :loading="true" color="#fff"
                                                                                             size="12px"
                                                                                             v-if="global.pendingRequests > 0"></clip-loader>
                                                                                Test Call
                                                                            </b-button>
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <th width="150">id</th>
                                                                <td>Outbound record id</td>
                                                            </tr>
                                                            <tr>
                                                                <th width="150">event_type</th>
                                                                <td>carrier_starts_journey_outbound</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </b-col><!--/b-col-->
                                                <b-col sm="12"><hr></b-col>
                                            </b-row>
                                        </b-col><!--/b-col-->

                                        <b-col sm="12 mt-4">
                                            <b-row>
                                                <b-col sm="6">
                                                    <b-form-group
                                                        :label="$t('input.whenClientCreateTransportRequest')+' *'"
                                                        label-for="client_create_transport_request"
                                                        :invalid-feedback="formErrors.first('client_create_transport_request')">
                                                        <b-form-input
                                                            id="client_create_transport_request"
                                                            v-model="compoundWebhookFields.client_create_transport_request"
                                                            type="text"
                                                            :state="((formErrors.has('client_create_transport_request') ? false : null))"
                                                        ></b-form-input>

                                                        <small>
                                                            Call when client or SYSTEM create a transport request
                                                        </small>
                                                    </b-form-group>
                                                </b-col><!--/b-col-->
                                                <b-col sm="6">
                                                    <table class="table table-sm table-striped">
                                                        <tbody>
                                                            <tr>
                                                                <th colspan="10">
                                                                    <div class="clearfix">
                                                                        <div class="pull-left">
                                                                            Params that you will receive
                                                                        </div>
                                                                        <div class="pull-right">
                                                                            <b-button
                                                                                size="sm"
                                                                                type="button"
                                                                                variant="dark"
                                                                                :disabled="global.pendingRequests > 0"
                                                                                @click="handleCompoundTestCall('client_create_transport_request')"
                                                                                v-b-tooltip.hover
                                                                            >
                                                                                <clip-loader style="display: inline" :loading="true" color="#fff"
                                                                                             size="12px"
                                                                                             v-if="global.pendingRequests > 0"></clip-loader>
                                                                                Test Call
                                                                            </b-button>
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <th>id</th>
                                                                <td>Order record id</td>
                                                            </tr>
                                                            <tr>
                                                                <th width="150">event_type</th>
                                                                <td>client_create_transport_request</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </b-col><!--/b-col-->
                                                <b-col sm="12"><hr></b-col>
                                            </b-row>
                                        </b-col><!--/b-col-->

                                        <b-col sm="12 mt-4">
                                            <b-row>
                                                <b-col sm="6">
                                                    <b-form-group
                                                            :label="$t('input.whenCarrierSpotStartJourney')+' *'"
                                                            label-for="carrier_starts_spot_journey"
                                                            :invalid-feedback="formErrors.first('carrier_starts_spot_journey')">
                                                        <b-form-input
                                                                id="carrier_starts_spot_journey"
                                                                v-model="compoundWebhookFields.carrier_starts_spot_journey"
                                                                type="text"
                                                                :state="((formErrors.has('carrier_starts_spot_journey') ? false : null))"
                                                        ></b-form-input>
                                                    </b-form-group>
                                                    <small>
                                                        Call when Carrier or driver starting the journey of SPOT
                                                    </small>
                                                </b-col><!--/b-col-->
                                                <b-col sm="6">
                                                    <table class="table table-sm table-striped">
                                                        <tbody>
                                                        <tr>
                                                            <th colspan="10">
                                                                <div class="clearfix">
                                                                    <div class="pull-left">
                                                                        Params that you will receive
                                                                    </div>
                                                                    <div class="pull-right">
                                                                        <b-button
                                                                                size="sm"
                                                                                type="button"
                                                                                variant="dark"
                                                                                :disabled="global.pendingRequests > 0"
                                                                                @click="handleCompoundTestCall('carrier_starts_spot_journey')"
                                                                                v-b-tooltip.hover
                                                                        >
                                                                            <clip-loader style="display: inline" :loading="true" color="#fff"
                                                                                         size="12px"
                                                                                         v-if="global.pendingRequests > 0"></clip-loader>
                                                                            Test Call
                                                                        </b-button>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th width="150">id</th>
                                                            <td>Spot record id</td>
                                                        </tr>
                                                        <tr>
                                                            <th width="150">driver</th>
                                                            <td>if action did by driver</td>
                                                        </tr>
                                                        <tr>
                                                            <th width="150">event_type</th>
                                                            <td>carrier_starts_spot_journey</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </b-col><!--/b-col-->
                                                <b-col sm="12"><hr></b-col>
                                            </b-row>
                                        </b-col><!--/b-col-->

                                        <b-col sm="12 mt-4">
                                            <b-row>
                                                <b-col sm="6">
                                                    <b-form-group
                                                            :label="$t('input.whenCarrierCreateSpotUnloadRequest')+' *'"
                                                            label-for="carrier_create_spot_unload"
                                                            :invalid-feedback="formErrors.first('carrier_create_spot_unload')">
                                                        <b-form-input
                                                                id="carrier_create_spot_unload"
                                                                v-model="compoundWebhookFields.carrier_create_spot_unload"
                                                                type="text"
                                                                :state="((formErrors.has('carrier_create_spot_unload') ? false : null))"
                                                        ></b-form-input>
                                                    </b-form-group>
                                                    <small>
                                                        Call when Carrier or Driver is on Gate of Compound and Request for Unload <br>
                                                        Only call if the compound unload request is pending
                                                    </small>
                                                </b-col><!--/b-col-->
                                                <b-col sm="6">
                                                    <table class="table table-sm table-striped">
                                                        <tbody>
                                                        <tr>
                                                            <th colspan="10">
                                                                <div class="clearfix">
                                                                    <div class="pull-left">
                                                                        Params that you will receive
                                                                    </div>
                                                                    <div class="pull-right">
                                                                        <b-button
                                                                                size="sm"
                                                                                type="button"
                                                                                variant="dark"
                                                                                :disabled="global.pendingRequests > 0"
                                                                                @click="handleCompoundTestCall('carrier_create_spot_unload')"
                                                                                v-b-tooltip.hover
                                                                        >
                                                                            <clip-loader style="display: inline" :loading="true" color="#fff"
                                                                                         size="12px"
                                                                                         v-if="global.pendingRequests > 0"></clip-loader>
                                                                            Test Call
                                                                        </b-button>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th width="150">id</th>
                                                            <td>Inbound record id</td>
                                                        </tr>
                                                        <tr>
                                                            <th width="150">event_type</th>
                                                            <td>carrier_create_spot_unload</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </b-col><!--/b-col-->
                                                <b-col sm="12"><hr></b-col>
                                            </b-row>
                                        </b-col><!--/b-col-->

                                    </b-row><!--/b-row-->
                                    <b-row class="operation-footer">
                                        <b-col sm="12">
                                            <b-button
                                                size="sm"
                                                type="submit"
                                                variant="primary"
                                                :disabled="global.pendingRequests > 0"
                                                v-b-tooltip.hover :title="$t('button.title.save')"
                                            >
                                                <clip-loader style="display: inline" :loading="true" color="#fff"
                                                             size="12px"
                                                             v-if="global.pendingRequests > 0"></clip-loader>
                                                <i class="fa fa-save mr-1"></i>
                                                {{ $t('button.save') }}
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </form>
                    </b-container>
                </div>
            </div>
        </div>

        <div class="card"  v-if="$global.hasRole('client')">
            <div class="card-header card-header-flex pb-2">
                <div class="w-100">
                    <div class="row">
                        <div class="col-6">
                            <h5 class="mt-3 ml-0 mr-3 mb-2 text-uppercase">
                                <span>WEBHOOKS</span> <br>
                                <small>Every Request should be POST</small> <br>
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="webhooks-operation">
                    <b-container fluid>
                        <form @submit.prevent="handleClientWebhookSubmit" autocomplete="off">
                            <b-row class="p-0">
                                <b-col cols="12" md="12" lg="12" sm="12" class="p-0">
                                    <b-row>
                                        <b-col sm="12 mt-4">
                                            <b-row>
                                                <b-col sm="6">
                                                    <b-form-group
                                                        :label="$t('input.whenOrderLoadStatusUpdated')+' *'"
                                                        label-for="client_order_load_status_updated"
                                                        :invalid-feedback="formErrors.first('client_order_load_status_updated')">
                                                        <b-form-input
                                                            id="client_order_load_status_updated"
                                                            v-model="clientWebhookFields.client_order_load_status_updated"
                                                            type="text"
                                                            :state="((formErrors.has('client_order_load_status_updated') ? false : null))"
                                                        ></b-form-input>
                                                    </b-form-group>
                                                    <small>
                                                        Call when order load status updated
                                                    </small>
                                                </b-col><!--/b-col-->
                                                <b-col sm="6">
                                                    <table class="table table-sm table-striped">
                                                        <tbody>
                                                        <tr>
                                                            <th colspan="10">
                                                                <div class="clearfix">
                                                                    <div class="pull-left">
                                                                        Params that you will receive
                                                                    </div>
                                                                    <div class="pull-right">
                                                                        <b-button
                                                                            size="sm"
                                                                            type="button"
                                                                            variant="dark"
                                                                            :disabled="global.pendingRequests > 0"
                                                                            @click="handleClientTestCall('client_order_load_status_updated')"
                                                                            v-b-tooltip.hover
                                                                        >
                                                                            <clip-loader style="display: inline" :loading="true" color="#fff"
                                                                                         size="12px"
                                                                                         v-if="global.pendingRequests > 0"></clip-loader>
                                                                            Test Call
                                                                        </b-button>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th width="150">order_code</th>
                                                            <td>Order Code</td>
                                                        </tr>
                                                        <tr>
                                                            <th width="150">load_status_code</th>
                                                            <td>As a number between 1 to 17</td>
                                                        </tr>
                                                        <tr>
                                                            <th width="150">load_status</th>
                                                            <td>As a string like: Produced, Added to Load, inTransit</td>
                                                        </tr>
                                                        <tr>
                                                            <th width="150">process_type</th>
                                                            <td>like inboundLoad.add or inboundLoad.update or inboundLoad.carrier.accept</td>
                                                        </tr>
                                                        <tr>
                                                            <th width="150">event_type</th>
                                                            <td>client_order_load_status_updated</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </b-col><!--/b-col-->

                                                <b-col sm="12"><hr></b-col>
                                            </b-row>
                                        </b-col><!--/b-col-->
                                    </b-row><!--/b-row-->
                                    <b-row class="operation-footer">
                                        <b-col sm="12">
                                            <b-button
                                                size="sm"
                                                type="submit"
                                                variant="primary"
                                                :disabled="global.pendingRequests > 0"
                                                v-b-tooltip.hover :title="$t('button.title.save')"
                                            >
                                                <clip-loader style="display: inline" :loading="true" color="#fff"
                                                             size="12px"
                                                             v-if="global.pendingRequests > 0"></clip-loader>
                                                <i class="fa fa-save mr-1"></i>
                                                {{ $t('button.save') }}
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </form>
                    </b-container>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {request} from "../../util/Request";
    import Error from "../../util/Error";
    import {mapState} from "vuex";
    import {refresh, removeStorage, setStorage} from "../../util/Utils";

    const DEFAULT_FORM_STATE = {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        profile_pic: null,
        api_secret: null,
    }

    const DEFAULT_COMPOUND_WEBHOOK_STATE = {
        carrier_starts_journey: null,
        carrier_create_inbound_unload: null,
        client_add_or_update_additional_service_request: null,
        carrier_starts_journey_outbound: null,
        client_create_transport_request: null,
        carrier_starts_spot_journey: null,
        carrier_create_spot_unload: null,
        _method: 'post',
    }

    const DEFAULT_CLIENT_WEBHOOK_STATE = {
        client_order_load_status_updated: null,
        _method: 'post',
    }

    export default {
        data() {
            return {
                formFields: {...DEFAULT_FORM_STATE},
                compoundWebhookFields: {...DEFAULT_COMPOUND_WEBHOOK_STATE},
                clientWebhookFields: {...DEFAULT_CLIENT_WEBHOOK_STATE},
                formErrors: new Error({}),
                user: {},
                isPassword: true
            }
        },
        mounted() {
            this.getProfile()

            if(this.$global.hasRole('suppliercompound')) {
                this.getCompoundWebhooks()
            }

            if(this.$global.hasRole('client')) {
                this.getClientWebhooks()
            }

            this.$title = this.$t('topBar.navigations.modules.profile')
        },
        methods: {
            async getProfile() {
                try {
                    const response = await request({
                        url: '/a/auth/user',
                        method: 'get',
                    })

                    const {data} = response
                    this.formFields = data
                } catch (e)  {

                }
            },
            async handleSubmit() {
                this.formErrors = new Error({})
                try {
                    const response = await request({
                        url: 'users/profile',
                        method: 'post',
                        data: this.formFields,
                    })

                    const {access_token} = response.data
                    if(access_token) {
                        removeStorage(`auth`)
                        setStorage(`auth`, JSON.stringify(response.data))
                    }

                    this.itemUpdated()
                    refresh()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }

                    this.handleServerError(error)
                }
            },

            async getCompoundWebhooks() {
                try {
                    const response = await request({
                        url: '/s/compound/webhooks',
                        method: 'get',
                    })

                    const {data} = response
                    this.compoundWebhookFields.carrier_starts_journey = (_.find(data, {webhook: 'carrier_starts_journey'}) || {}).url
                    this.compoundWebhookFields.carrier_create_inbound_unload = (_.find(data, {webhook: 'carrier_create_inbound_unload'}) || {}).url
                    this.compoundWebhookFields.client_add_or_update_additional_service_request = (_.find(data, {webhook: 'client_add_or_update_additional_service_request'}) || {}).url
                    this.compoundWebhookFields.carrier_starts_journey_outbound = (_.find(data, {webhook: 'carrier_starts_journey_outbound'}) || {}).url
                    this.compoundWebhookFields.client_create_transport_request = (_.find(data, {webhook: 'client_create_transport_request'}) || {}).url
                    this.compoundWebhookFields.carrier_starts_spot_journey = (_.find(data, {webhook: 'carrier_starts_spot_journey'}) || {}).url
                    this.compoundWebhookFields.carrier_create_spot_unload = (_.find(data, {webhook: 'carrier_create_spot_unload'}) || {}).url
                } catch (e) {

                }
            },
            async handleCompoundWebhookSubmit() {
                this.formErrors = new Error({})
                try {
                    const response = await request({
                        url: 's/compound/webhooks/store',
                        method: 'post',
                        data: {...this.compoundWebhookFields},
                    })

                    this.itemUpdated()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }

                    this.handleServerError(error)
                }
            },
            async handleCompoundTestCall(call_for) {
                try {
                    const response = await request({
                        url: 's/compound/webhooks/t/webhook',
                        method: 'post',
                        data: {
                            webhook: call_for
                        }
                    })
                } catch (e) {
                    this.handleServerError(e)
                }
            },
            handleVisibilityClick() {
                this.isPassword = !this.isPassword
            },
            generateApiSecret() {
                request({
                    url: '/users/generate/secret',
                    method: 'post',
                })
                .then((response) => {
                    this.formFields.api_secret = response.data.api_secret;
                })
                .catch((error) => {
                })
            },

            async getClientWebhooks() {
                try {
                    const response = await request({
                        url: '/client/webhooks',
                        method: 'get',
                    })

                    const {data} = response
                    this.clientWebhookFields.client_order_load_status_updated = (_.find(data, {webhook: 'client_order_load_status_updated'}) || {}).url
                } catch (e) {

                }
            },
            async handleClientWebhookSubmit() {
                this.formErrors = new Error({})

                try {
                    const response = await request({
                        url: '/client/webhooks/store',
                        method: 'post',
                        data: {...this.clientWebhookFields},
                    })

                    this.itemUpdated()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }

                    this.handleServerError(error)
                }
            },
            async handleClientTestCall(call_for) {
                try {
                    const response = await request({
                        url: 'client/webhooks/t/webhook',
                        method: 'post',
                        data: {
                            webhook: call_for
                        }
                    })
                } catch (e) {
                    this.handleServerError(e)
                }
            },
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
